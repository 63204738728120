<template>
  <NuxtLink
    v-if="car"
    class="flex-grow flex-shrink-0 basis-0 flex flex-col no-underline text-black overflow-hidden"
    :class="
      isCardLg
        ? 'rounded-m clip-lg pointer-events-none aspect-[3/2]'
        : 'rounded-s clip-sm aspect-square'
    "
    :to="localePath({ name: `cars-id`, params: { id: car.id } })"
    ref="target"
    :style="{
      transform: cardTransform?.transform,
      'box-shadow': cardTransform?.shadow,
      transition: 'transform 2s cubic-bezier(0,0,0,1), box-shadow 2s cubic-bezier(0,0,0,1)',
    }"
  >
    <!-- <pre>{{ promotions }}</pre> -->
    <div class="grow h-1/2 clip-bottom relative" :class="cardColor">
      <div
        v-if="!isCardSm"
        class="flex justify-between p-4 text-black-50 absolute w-full"
        :class="isCardLg ? 'p-5' : ''"
      >
        <p :class="isCardLg ? 'text-md' : 'text-xs'">
          <span v-if="car.nrOfSeats">{{ car.nrOfSeats }} {{ $t('car.seats') }}</span>
          <span v-if="car.fuelType === 'electric'"
            >{{ $t('ui.comma') + ' ' }} {{ $t('car.electric') }}</span
          >
          <span v-else-if="car.transmissionType"
            >{{ $t('ui.comma') + ' ' }} {{ $t('car.transmission.' + car.transmissionType) }}</span
          >
        </p>
        <p
          class="rounded-full border aspect-square block h-[1.2rem] text-center leading-[1.45]"
          :class="isCardLg ? 'text-md h-[1.3rem]' : 'text-xs h-[1.2rem]'"
        >
          {{ car.vehicleType }}
        </p>
      </div>
      <img
        v-if="isCardLg"
        class="w-full h-full object-contain p-4"
        :class="{ 'pt-8': !isCardSm }"
        :src="car.imageIMG || '/img/car_placeholder.png'"
        :alt="car.vehicleMake + ' ' + car.vehicleModel"
        :style="{
          transform: cardTransform?.image,
          transition: 'transform 2s cubic-bezier(0,0,0,1)',
        }"
      />
      <img
        v-else
        class="w-full h-full object-contain p-4"
        :class="{ 'pt-8': !isCardSm }"
        :src="car.thumbIMG || '/img/car_placeholder.png'"
        :alt="car.vehicleMake + ' ' + car.vehicleModel"
        :style="{
          transform: cardTransform?.image,
          transition: 'transform 2s cubic-bezier(0,0,0,1)',
        }"
      />
    </div>
    <div
      class="dashed-line"
      :class="isCardLg ? 'clip-lg' : 'clip-sm'"
      v-bind:class="lineColor"
    ></div>
    <div class="clip-top px-3 pt-3 pb-2" :class="cardColor">
      <div v-if="isCardSm">
        <p class="font-medium leading-tight flex-shrink-0">
          <span v-if="duration && discount && duration >= 60">
            {{ $t('ui.currency') }}
            {{ (car.dailyRateCUR * 30 * discount[car.vehicleType]).toFixed(2) }}/
            {{ $t('ui.month') }}
          </span>
          <span v-else-if="duration && discount">
            {{ $t('ui.currency') }}
            {{ (car.dailyRateCUR * duration * discount[car.vehicleType]).toFixed(2) }}
            {{ $t('car.summary.total') }}
          </span>

          <span v-else>
            {{ $t('ui.currency') }}
            {{ (car.dailyRateCUR * $promotionData[car.vehicleType]).toFixed(2) }}{{ $t('ui.slash')
            }}{{ $t('ui.day') }}
          </span>
        </p>
        <p class="leading-tight overflow-hidden text-ellipsis whitespace-nowrap">
          {{ car.vehicleMake }} {{ car.vehicleModel }}
        </p>
      </div>
      <div v-else>
        <div class="flex justify-between">
          <p
            class="font-medium leading-tight overflow-hidden text-ellipsis whitespace-nowrap"
            :class="isCardLg ? 'text-base pt-1 pl-2' : ''"
          >
            {{ car.vehicleModel }}
          </p>
          <p v-if="!isCardLg" class="font-medium leading-tight flex-shrink-0">
            <span v-if="duration && discount && duration >= 60"
              >{{ $t('ui.currency') }}
              {{ (car.dailyRateCUR * 30 * discount[car.vehicleType]).toFixed(2) }}</span
            >
            <span v-else-if="duration && discount"
              >{{ $t('ui.currency') }}
              {{ (car.dailyRateCUR * duration * discount[car.vehicleType]).toFixed(2) }}</span
            >
            <span v-else
              >{{ $t('ui.currency') }}
              {{ (car.dailyRateCUR * $promotionData[car.vehicleType]).toFixed(2)
              }}{{ $t('ui.slash') }}{{ $t('ui.day') }}</span
            >
          </p>
        </div>
        <div class="flex justify-between">
          <p
            class="leading-tight overflow-hidden text-ellipsis whitespace-nowrap"
            :class="isCardLg ? 'text-base pb-2 pl-2' : ''"
          >
            {{ car.vehicleMake }}
          </p>
          <p v-if="duration && discount && !isCardLg && duration >= 60">{{ $t('ui.month') }}</p>
          <p v-else-if="duration && discount && !isCardLg">{{ $t('car.summary.total') }}</p>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Vehicle, PromotionDiscount } from '@/types/backend';

const localePath = useLocalePath();

const { $promotionData } = useNuxtApp();

const props = defineProps<{
  car: Vehicle;
  discount: PromotionDiscount;
  duration: number;
  isCardSm?: boolean;
  isCardMd?: boolean;
  isCardLg?: boolean;
}>();
let cardColor = ref('');
let lineColor = ref('');

const target = ref(null);
const { elementX, elementY, isOutside, elementHeight, elementWidth } = useMouseInElement(target);

const cardTransform = computed(() => {
  if (props.isCardLg) return;
  if (isTouchDevice()) return;
  const maxRotation = 6;

  const rX = (maxRotation / 2 - (elementY.value / elementHeight.value) * maxRotation).toFixed(2);

  const rY = ((elementX.value / elementWidth.value) * maxRotation - maxRotation / 2).toFixed(2);

  return isOutside.value
    ? {
        transform: '',
        shadow: '',
        image: '',
      }
    : {
        transform: `perspective(${elementWidth.value}px) rotateX(${rX}deg) rotateY(${rY}deg) scale(1.05)`,
        shadow: `${-rY * 4}px ${parseInt(rX) * 4}px 24px rgb(0,0,0,.2)`,
        image: `translate(${-rY * 1.3}px, ${parseInt(rX) * 1.3}px)`,
      };
});
function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

if (props.isCardSm) {
  cardColor = ref('bg-white');
  lineColor = ref('dashed-line-white');
} else if (props.car.vehicleType === 'S') {
  cardColor = ref('bg-pink');
  lineColor = ref('dashed-line-pink');
} else if (props.car.vehicleType === 'M') {
  cardColor = ref('bg-yellow');
  lineColor = ref('dashed-line-yellow');
} else if (props.car.vehicleType === 'L') {
  cardColor = ref('bg-orange');
  lineColor = ref('dashed-line-orange');
} else {
  cardColor = ref('bg-white');
  lineColor = ref('dashed-line-white');
  console.warn('Size of car is not defined or an invalid value');
}
</script>
<style scoped>
.clip-lg {
  --circle-size: 10px;
  --gap: 8px;
  --height: 2px;
}

.clip-sm {
  --circle-size: 6px;
  --gap: 4px;
  --height: 1px;
}

.clip-bottom {
  --offset-circle-size: calc(var(--circle-size) * 1.05);
  mask-image: radial-gradient(
      circle at 0% calc(100% + 0.5px),
      transparent 0,
      transparent var(--circle-size),
      black var(--offset-circle-size)
    ),
    radial-gradient(
      circle at 100% calc(100% + 0.5px),
      transparent 0,
      transparent var(--circle-size),
      black var(--offset-circle-size)
    );
  mask-position:
    bottom left,
    bottom right;
  mask-repeat: no-repeat;
  mask-size: 51% 100%;
}

.clip-top {
  --offset-circle-size: calc(var(--circle-size) * 1.05);
  mask-image: radial-gradient(
      circle at 0% -0.5px,
      transparent 0,
      transparent var(--circle-size),
      black var(--offset-circle-size)
    ),
    radial-gradient(
      circle at 100% -0.5px,
      transparent 0,
      transparent var(--circle-size),
      black var(--offset-circle-size)
    );
  mask-position:
    bottom left,
    bottom right;
  mask-repeat: no-repeat;
  mask-size: 51% 100%;
}

.dashed-line-white {
  height: var(--height);
  background: repeating-linear-gradient(
    to right,
    white 0,
    white var(--gap),
    transparent var(--gap),
    transparent calc(var(--gap) * 2)
  );
  /* background-position: calc(50% - var(--gap) / 2); */
  margin-left: calc(var(--circle-size) + 0.1%);
  margin-right: calc(var(--circle-size) + 0.1%);
}

.dashed-line-pink {
  height: var(--height);
  background: repeating-linear-gradient(
    to right,
    #fdb9dd 0,
    #fdb9dd var(--gap),
    transparent var(--gap),
    transparent calc(var(--gap) * 2)
  );
  /* background-position: calc(50% - var(--gap) / 2); */
  margin-left: calc(var(--circle-size) + 0.1%);
  margin-right: calc(var(--circle-size) + 0.1%);
}

.dashed-line-yellow {
  height: var(--height);
  background: repeating-linear-gradient(
    to right,
    #fbd302 0,
    #fbd302 var(--gap),
    transparent var(--gap),
    transparent calc(var(--gap) * 2)
  );
  /* background-position: calc(50% - var(--gap) / 2); */
  margin-left: calc(var(--circle-size) + 0.1%);
  margin-right: calc(var(--circle-size) + 0.1%);
}

.dashed-line-orange {
  height: var(--height);
  background: repeating-linear-gradient(
    to right,
    #f25f12 0,
    #f25f12 var(--gap),
    transparent var(--gap),
    transparent calc(var(--gap) * 2)
  );
  /* background-position: calc(50% - var(--gap) / 2); */
  margin-left: calc(var(--circle-size) + 0.1%);
  margin-right: calc(var(--circle-size) + 0.1%);
}
</style>
